import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Home/Hero';
import Testimonials from '../components/Home/Testimonials';
import Pricing from '../components/Home/Pricing';
import SupportedPlatforms from '../components/Home/SupportedPlatforms';
import Indoctrination from '../components/Home/Indoctrination';
import USP from '../components/Home/USP';
import Benefits from '../components/Home/Benefits';
import Steps from '../components/Home/Steps';
import CTA from '../components/Home/CTA';
import FAQ from '../components/Home/FAQ';
import { ChevronUpIcon } from '@heroicons/react/solid';

const HomePage: React.FC = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Check scroll position and show/hide button
  useEffect(() => {
    // Function to check scrollTop at intervals
    const checkScrollPosition = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //console.log('Custom Check Scroll Position:', scrollTop);
      if (scrollTop > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    // Set interval to check scroll position every 100ms
    const intervalId = setInterval(checkScrollPosition, 100);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Scroll back to hero section
  const scrollToTop = () => {
    const heroSection = document.getElementById('hero');
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return(
    <>
      <div className="relative overflow-x-hidden">
        {/* Background shapes placed behind everything except Hero */}
        <div className="absolute left-[-50%] top-0 w-2/3 h-full bg-orange-500 opacity-10 blur-3xl z-[-20]"></div>
        <div className="absolute right-[-50%] top-0 w-2/3 h-full bg-orange-500 opacity-10 blur-3xl z-[-20]"></div>

        <div className="relative">
          {/* Navigation Bar */}
          <Navbar />

          {/* Hero Section */}
          <section id="hero" className="snap-y snap-start snap-proximity bg-[#233b59] z-[10] relative">
            <Hero />
          </section>
          
          {/* Indoctrination Section */}
          <section id="text" className="snap-y snap-start snap-proximity z-[1] relative">
            <Indoctrination />
          </section>

          {/* Unique Selling Proposition Section */}
          <section id="usp" className="relative z-[1]">
            <USP />
          </section>

          {/* Supported Platforms Section */}
          <section id="platforms" className="relative z-[1]">
            <SupportedPlatforms />
          </section>

          {/* Benefits Section */}
          <section id="benefits" className="relative z-[1]">
            <Benefits />
          </section>

          {/* Steps Section 
          <section id="steps" className="min-h-screen relative z-[1]">
            <Steps />
          </section>*/}

          {/* Testimonials 
          <section id="testimonials" className="min-h-screen relative z-[1]">
            <Testimonials />
          </section>*/}

          {/* Call to Action Section */}
          <section id="cta" className="min-h-screen relative z-[1]">
            <CTA />
          </section>

          {/* Pricing Options 
          <section id="pricing" className="min-h-screen relative z-[1]">
            <Pricing />
          </section>*/}

          {/* FAQ Section */}
          <section id="faq" className="min-h-screen relative z-[1]">
            <FAQ />
          </section>

          {/* Footer */}
          <Footer />
          {/* Scroll to Top Button */}
          {showScrollButton && (
            <button
              onClick={scrollToTop}
              className="fixed bottom-6 right-6 z-[100] bg-secondary opacity-40 text-white p-2 rounded-full shadow-xl hover:bg-secondary hover:opacity-100 transition"
            >
              <ChevronUpIcon className="md:h-12 md:w-12 h-9 w-9 flex-shrink-0" />
            </button>        
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage;
