import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link'; // Import Link from Next.js
import Button from '../Button'; // Assuming Button component is in the same directory

const Hero = () => {
  const [vh, setVh] = useState(100);

  useEffect(() => {
    // Set the viewport height CSS variable on component mount and resize
    const setViewportHeight = () => setVh(window.innerHeight * 0.01);
    setViewportHeight();

    // Add event listener to handle resize
    window.addEventListener('resize', setViewportHeight);

    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);

  return (
    <div className="hero bg-cover bg-center w-screen min-w-full relative overflow-hidden"
         style={{ minHeight: `calc(${vh} * 100px)` }}>
      {/* Left and Right Black Shadows */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 w-2/3 h-full bg-gradient-to-r from-black opacity-70"></div>
        <div className="absolute top-0 right-0 w-2/3 h-full bg-gradient-to-l from-black opacity-70"></div>
      </div>

      {/* Text and Image Content (on top of everything) */}
      <div className="relative z-10 container mx-auto h-full flex flex-col justify-center px-4 text-left">
        <div className="mt-[100px] md:mt-[200px] text-white">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-6 text-white font-sans md:w-3/5">
            Investi con broker online?<br />Togliti il fisco dal groppone e risparmia più di €250 di penali!
          </h1>
          <p className="text-base sm:text-xl mb-4 sm:mb-6 leading-7 sm:leading-9 md:w-1/2">
            Stanco di affidarti a pasticcioni? <br/>Ciao, sono <b>Geraldone</b>. <br/>
            Raccontami delle tue piattaforme di trading e ti mando la tua dichiarazione in poche ore e senza errori.
          </p>
          
          {/* Replace anchor tag with Next.js Link */}
          <Link href="/register">
              <Button variant="hero">
                Calcola la tua Dichiarazione
              </Button>
          </Link>
        </div>
      </div>

      {/* Image of Geraldone */}
      <div className="absolute bottom-[-10px] md:bottom-[-20px] right-0 md:right-0 z-10">
      <Image
        src="/images/geraldone.png"
        alt="Accountant"
        width={450} // Max width for largest screens
        height={450}
        className="w-full h-auto max-w-[450px]"
        sizes="(max-width: 640px) 250px, (max-width: 1024px) 350px, 450px"
        priority
      />
      </div>
    </div>
  );
};

export default Hero;
