import React from 'react';
import Button from '../Button';
import Link from 'next/link';

const CTA: React.FC = () => {
  return (
    <div className="relative py-10 md:2/3 lg:w-1/2 w-4/5 mx-auto mb-24 overscroll-x-hidden max-w-screen">
      <div className="container mx-auto flex flex-col justify-between items-center px-4 md:px-0 space-y-6 md:space-y-0">
        {/* Left Text */}
        <div className="md:w-3/5 text-left mr-auto">
          <h2 className="text-2xl font-bold mb-4 text-[#00008b]">Geraldone è una certezza.</h2>
          <p className="text-base mb-6 leading-8 text-black">
            Geraldone è nato per gli investitori come te.<br />
            Il suo staff è composto da programmatori, commercialisti e stagisti sottopag.. ehm.. pagati bene.<br />
            Uniti dalla passione per la finanza personale, sappiamo cosa ti serve perché abbiamo vissuto i tuoi stessi problemi.
          </p>
        </div>
        {/* Right Text */}
        <div className="md:w-1/2 text-left ml-auto">
          <h2 className="text-2xl font-bold mb-4 mt-8 text-[#00008b]">Ricordati,</h2>
          <p className="text-base mb-6 leading-8 text-black">
            La scadenza fiscale per la dichiarazione redditi persone fisiche 2024 (anno fiscale 2023) è il 30 Ottobre 2024!<br />
            Per garantirti l&apos;utilizzo dei nostri server, <strong>calcola subito la tua dichiarazione</strong>.<br />
            NON aspettare le ultime 2 settimane!
          </p>
      </div>
      {/* Background shapes */}
        <div className="purple-rectangle-2 absolute bg-[#00008b] opacity-50 w-[50px] h-[400px] rounded-full bottom-[-4vh] right-[56%] hidden md:block"></div>
        <div className="purple-rectangle-3 absolute bg-[#00008b] opacity-20 w-[50px] h-[200px] rounded-full bottom-[-2vh] right-[67%] lg:right-[68%] hidden md:block"></div>
    </div>
      {/* Button */}
      <Link href="/register">
        <Button 
          variant="orange" 
          className="block bg-secondary text-primary text-xl mx-auto md:mt-[-20px] md:text-2xl py-4 px-8 w-full md:mx-auto rounded-full mx-auto font-normal transition hover:bg-primary hover:text-white hover:opacity-100 hover:scale-110"
        >
          <span>Calcola la tua dichiarazione <strong>ASAP</strong></span>
        </Button>
      </Link>
    </div>
  );
};

export default CTA;
