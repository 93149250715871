import React, { useState } from 'react';
import PrivacyPolicyPopup from '../PrivacyPolicy';
import TermsAndConditionsPopup from '../Terms';
import Link from 'next/link';

interface FAQItem {
  id: number;
  question: JSX.Element;
  answer: JSX.Element;
}

const FAQ: React.FC = () => {
  const [activeFAQ, setActiveFAQ] = useState<number | null>(null);
  const [isTermsOpen, setTermsOpen] = useState<boolean>(false); 
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState<boolean>(false);

  const faqs: FAQItem[] = [
    {
      id: 1,
      question: (
        <>
          Non vedo le mie app dei trading tra le piattaforme servite.<br /> Me le calcolate lo stesso?
        </>
      ),
      answer: (
        <>
          <strong>NO.</strong><br/>
          Preferiamo concentrarci su poche piattaforme per migliorare la qualità dei calcoli ed evitare pasticci.<br />
          Puoi controllare le piattaforme che serviamo in questa <strong><Link href="#platforms" className='underline'>sezione</Link></strong>. <br />
          Diamo priorità a quelle più richieste.<br />
          {/*Ricorda che puoi votare per dare maggiore priorità alle tue piattaforme.*/}
        </>
      ),
    },
    {
      id: 2,
      question: <>Che dati devo darvi? Come faccio a caricarli?</>,
      answer: (
        <>
          Abbiamo bisogno delle tue transazioni durante tutti gli anni che hai utilizzato le piattaforme.
          N.B.: Ti guideremo passo dopo passo su come importare le tue transazioni.<br />
          Servono tutti gli anni per ottenere un&apos;accurata rendicontazione di tutti i valori di acquisto.<br/>
          Anche se l&apos;agenzia delle entrate non può andare indietro per più di 5 anni con le sanzioni, il tuo orizzonte di investimento può essere molto più lungo e ti servono i valori iniziali.<br />
          Se ti è capitato di chiudere delle piattaforme, ti consigliamo di reperire tutto quello che puoi e conservarlo.
        </>
      ),
    },
    {
      id: 17,
      question: <>Ho più di una piattaforma. Quanti report riceverò?</>,
      answer: (
        <>
          <strong>Uno solo.</strong><br />
          La dichiarazione viene integrata automaticamente con tutti i tuoi broker.<br />
          Basta caricare i dati, e Geraldone creerà un report unificato che potrai facilmente copiare nella tua dichiarazione precompilata.<br/>
          Esattamente come ti serve.
        </>
      ),
    },
    {
      id: 4,
      question: <>Perché dovrei affidarmi a Geraldone?</>,
      answer: (
        <>
          Perché no? <br/> Geraldone è il tuo contabile digitale che ti risolve i problemi in pochi
          click, senza errori e sanzioni.
          Ti accompagneremo in ogni passaggio con istruzioni facili e intuitive; domande semplici con spiegazioni dettagliate per darti delle certezze.
          Ti diciamo esattamente cosa devi fare per inviare la tua dichiarazione.
          Se anche l&apos;avessi già calcolata dal tuo CAF o commercialista, puoi sempre usare il nostro servizio per verificare che sia corretta e darti più tranquillità.
        </>
      ),
    },
    {
      id: 10,
      question: <>Geraldone funziona anche per le criptovalute?</>,
      answer: (
        <>
          Sì, supportiamo la dichiarazione di investimenti in criptovalute.<br />
          Calcoliamo le imposte sulle criptoattività come specificato dalla Legge di Bilancio 2023.
          Compiliamo i campi sia per il monitoraggio che le plusvalenze, anche quando nessuna imposta è dovuta.
        </>
      ),
    },
    {
      id: 5,
      question: <>Quali tipi di investimenti posso elaborare?</>,
      answer: (
        <>
          Geraldone calcola automaticamente tutti i principali prodotti finanziari, tra cui:
          <br />- Azioni
          <br />- Obbligazioni e titoli di Stato
          <br />- ETF armonizzati e non
          <br />- Criptovalute (includendo NFT, stacking, mining)
          <br />- Futures
          <br />- Opzioni
          <br />- CFD (Contratti per Differenza)
          <br />- Fondi comuni d’investimento
          <br />- Commodities (oro, argento, petrolio, etc.)
          <br />- Conti di risparmio e conti deposito
          <br />- Altri derivati.
          <br />
          Copriamo tutto il necessario per una dichiarazione fiscale completa e accurata.
        </>
      ),
    },
    {
      id: 6,
      question: <>Oltre agli investimenti ho anche degli immobili all&apos;estero e redditi da libero professionista, potete calcolare anche quelle?</>,
      answer: (
        <>
          Ci concentriamo solo sugli investimenti finanziari, specificatamente conti trading, conti criptovalute e conti esteri che operano in regime dichiarativo. <br/>
          Insomma, le aree dove i commercialisti e i CAF fanno più fatica.<br/>
        </>
      ),
    },
    {
      id: 7,
      question: <>Quanto tempo ci vuole per completare la dichiarazione?</>,
      answer: (
        <>
          Per le piattaforme supportate, il calcolo della dichiarazione può essere completato in
          poche ore.<br />
          Per le piattaforme non supportate, potrebbe richiedere un po&apos; più di tempo, ma il team farà il
          possibile per offrirti un ottimo servizio.
        </>
      ),
    },
    {
      id: 9,
      question: <>Il servizio è disponibile solo in Italia?</>,
      answer: (
        <>
          Geraldone è focalizzato su utenti residenti fiscalmente in Italia e sulle normative fiscali
          italiane.<br />
        </>
      ),
    },
    {
      id: 11,
      question: <>Cosa succede se sbaglio a dichiarare oppure non lo faccio proprio?</>,
      answer: (
        <>
          L&apos;agenzia delle entrate ha sempre svolto controlli a campione, ma ultimamente sta automatizzando i controlli.<br />
          Tutte le piattaforme di trading operanti in Italia devono rendicontare all&apos;Agenzia delle Entrate i dati dei loro clienti.<br />
          Se ancora non hai dichiarato, ti consigliamo di farlo al più presto per metterti in regola e evitare sanzioni.<br />
          Di solito, il processo è molto lungo, quindi per sanzionarti ci possono mettere anche dai 12 ai 24 mesi dopo l&apos;anno di riferimento.
          Il nostro obiettivo è appunto quello di evitarti queste spiacevoli sorptese attraverso un controllo sistematico dei tuoi dati.<br />
        </>
      ),
    },
    {
      id: 12,
      question: <>Che tipo di assistenza offrite in caso di problemi?</>,
      answer: (
        <>
          Offriamo supporto clienti via email e nell&apos;area di assistenza per qualsiasi dubbio o problema che possa
          sorgere durante il processo di dichiarazione. <br />
          Ricordati che sotto scadenza le richieste aumentano ed è più difficile risolvere i problemi in tempi brevi, quindi non aspettare all&apos;ultimo minuto.
          Siamo qui per aiutarti!
        </>
      ),
    },
    {
      id: 13,
      question: <>Come faccio a sapere se i miei dati sono stati elaborati correttamente?</>,
      answer: (
        <>
          Anche se Geraldone si basa sulle più recenti norme della fiscalità,
          ti consigliamo di rileggere il report prima di compilare i dati in dichiarazione.<br />
          Il report è fatto in modo da essere di facile comprensione per evitare qualsiasi errore.<br/>
          Se trovi qualcosa da modificare, puoi mandarci una richiesta nella sezione assistenza.<br/>
          In caso di errore, rielaboreremo il tuo report senza costi aggiuntivi.<br/>
          Siamo qui per aiutarti!
        </>
      ),
    },
  ];

  const handleToggle = (id: number) => {
    setActiveFAQ(activeFAQ === id ? null : id);
  };

  return (
    <div className="faq-section py-10 overscroll-x-hidden max-w-screen">
      <h2 className="text-center text-3xl font-semibold mb-10">FAQ</h2>
      <div className="faq-container space-y-6 max-w-2xl mx-auto px-4 mb-24">
        {faqs.map(faq => (
          <div key={faq.id} className="bg-primary bg-opacity-10 rounded-br-3xl p-6">
            <div className="flex justify-between text-left cursor-pointer" onClick={() => handleToggle(faq.id)}>
              <span className="font-bold text-lg">{faq.question}</span>
              <button className={`dropdown transform mt-4 ml-4 md:mt-0 transition-transform duration-300 ${activeFAQ === faq.id ? 'rotate-180' : ''}`}>
                <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
              </button>
            </div>
            <div className={`answer mt-4 text-left text-black font-lato ${activeFAQ === faq.id ? 'block' : 'hidden'}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
      <TermsAndConditionsPopup isOpen={isTermsOpen} closePopup={() => setTermsOpen(false)} />
      <PrivacyPolicyPopup isOpen={isPrivacyPolicyOpen} closePopup={() => setPrivacyPolicyOpen(false)} />
    </div>

  );
};

export default FAQ;
