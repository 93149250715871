import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Button from '../Button'; // Assuming Button is in the same directory

const USP: React.FC = () => {
  return (
    <div className="relative pt-10 mb-24 overscroll-x-hidden max-w-screen" id="about">
      {/* Background Images */}
      <Image 
        src="/images/USP-back.png" 
        alt="USP Background" 
        width={1200} 
        height={800} 
        className="USP-back absolute top-0 left-0 z-[-3]" 
      />
      <Image 
        src="/images/USP-back2.png" 
        alt="USP Background 2" 
        width={1200} 
        height={800} 
        className="USP-back-2 absolute top-0 left-0 z-[-2]" 
      />
      
      {/* Centered Heading */}
      <h2 className="text-center text-2xl font-bold text-[#00008b]">...la soluzione?</h2>
      
      {/* Logo and "GERALDONE" Text */}
      <div className="flex justify-center items-center text-center mt-24">
        <Image 
          src="/images/loghi/logo.png" 
          alt="Geraldone Logo" 
          width={80} 
          height={79} 
          className="mr-4" 
        />
        <p className="text-black text-4xl md:text-5xl font-extrabold tracking-widest">ERALDONE</p>
      </div>
      
      {/* Description with Image and Text */}
      <div className="mt-16 flex flex-col-reverse md:flex-row justify-center items-start relative md:w-1/2 mx-auto">
        <Image 
            src="/images/toony.png" 
            alt="Toony Image" 
            width={300} 
            height={340} 
            className="relative mt-[-8vw] ml-[35vw] mb-[-40px] md:ml-[-15vw] lg:ml-[-5vw] xl:ml-[5vw] md:mb-[-30px] md:mr-10 md:mt-16 md:scale-x-100 scale-x-[-1] md:scale-x-100" 
        />
        <p className="text-lg leading-8 w-4/5 md:w-1/2 mx-auto mt-8 md:mt-0 text-black">
            <span className="mb-8"><strong>Geraldone</strong> è il tuo commercialista digitale che ti risolve i problemi in pochi click. </span><br />
            <strong>Niente</strong> errori. <br />
            <strong>Niente</strong> sanzioni. <br />
            <strong>Niente</strong> abbonamenti. <br />
            E costa solo <span className="bold text-lg font-bold">€49!</span>
        </p>
      </div>

      {/* CTA Button */}
      <div className="relative flex justify-center mt-4">
        <Link href="/register">
          <Button 
            variant="orange" 
            className="block bg-[#ffa500] text-[#00008b] text-xl md:text-2xl py-4 px-8 mx-8 md:mx-0 md:px-24 xl:px-48 rounded-full mx-auto font-normal transition hover:bg-[#00008b] hover:text-white hover:opacity-100 hover:scale-110"
          >
            <span>Calcola la tua dichiarazione con <strong>Geraldone</strong></span>
          </Button>
        </Link>
         {/* SVG Shapes positioned relative to the button */}
         <div className="hidden md:block absolute bottom-[-40px] md:right-[5vw] lg:right-[14vw]">
          <div className="purple-rectangle bg-[#00008b] opacity-50 w-[50px] h-[280px] rounded-full"></div>
        </div>
        <div className="hidden md:block absolute bottom-[-40px] right-[5vw] lg:right-[16vw]">
          <div className="orange-rectangle bg-[#ffa500] opacity-40 w-[250px] h-[50px] rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default USP;
