import React from 'react';

interface Benefit {
  id: number;
  title: string;
  description: string;
  icon?: string; // Optional: you can add icons later if needed
}

const benefitsData: Benefit[] = [
  {
    id: 1,
    title: 'Affidabile',
    description:
      'La matematica non è un’opinione. Il risultato della dichiarazione deve essere un numero esatto. Calcoli corretti secondo normative e tassi ufficiali.',
  },
  {
    id: 2,
    title: 'Rapido',
    description:
      'Per le piattaforme più comuni, spesso in poche ore da quando ci sono tutte le informazioni. Per le altre piattaforme, ci possiamo mettere un po\' di più.',
  },
  {
    id: 3,
    title: 'Semplice',
    description:
      'Ti guidiamo mano per mano con un’interfaccia semplice e intuitiva. In pochi passaggi, potrai risolvere i tuoi problemi col fisco e stare senza pensieri.'
  },
  {
    id: 4,
    title: 'Economico',
    description:
      'Geraldone vola basso rispetto a rivolgersi a alternative più tradizionali o alle sanzioni che ti beccheresti dichiarando male. Riduce le tasse da pagare ottenendo tutte le compensazioni e i rimborsi.',
  },
  {
    id: 5,
    title: 'Sicuro',
    description:
      'I tuoi dati vengono utilizzati nel rispetto delle normative della privacy. Non vendiamo i dati sulle tue transazioni ad aziende terze.',
  },
  {
    id: 6,
    title: 'Aggiornato',
    description:
      'Il processo viene aggiornato ogni anno in base alle normative più recenti e ai tassi ufficiali. Aggiungiamo nuove funzionalità di continuo seguendo i vostri feedback.',
  },
];

const Benefits: React.FC = () => {
  return (
    <section className="py-10 relative mx-auto w-4/5 overscroll-x-hidden max-w-screen">

      <div className="flex flex-col justify-center gap-8 px-4 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5 mx-auto">
        {benefitsData.map((benefit, index) => (
          <div
            key={benefit.id}
            className={`
              benefit-card w-full md:w-4/5 px-8 py-16 
              ${index % 2 === 0 ? 'bg-secondary bg-opacity-5 border-secondary' : 'bg-primary bg-opacity-[.03] border-primary'} 
              border-4 border-dashed rounded-3xl relative flex flex-col items-center text-center
              ${index % 2 === 0 ? 'md:self-end' : 'md:self-start'} 
            `}
          >
            <h3 className="text-3xl font-bold text-primary mb-4">
              <span className="text-secondary text-6xl">{benefit.title.charAt(0)}</span>
              {benefit.title.slice(1)}
            </h3>
            <p className="text-gray-700 leading-relaxed">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;
