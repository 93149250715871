import React, { useState } from 'react';
import Button from '../Button'; // Assuming the Button component is already available
import { ChevronUpIcon, PlusIcon } from '@heroicons/react/outline'; // Import PlusIcon
import Image from 'next/image';

interface Platform {
  id: number;
  name: string;
  logoUrl?: string;
  status: 'ready' | 'WIP' | 'voting';
  votes?: number;
}

const SupportedPlatforms: React.FC = () => {
  const initialPlatforms: Platform[] = [
    { id: 1, name: 'Binance', logoUrl: '/images/loghi/binance.png', status: 'ready' },
    { id: 2, name: 'Degiro', logoUrl: '/images/loghi/degiro.png', status: 'ready' },
    { id: 3, name: 'Interactive Brokers', logoUrl: '/images/loghi/IBKR.png', status: 'WIP' },
    { id: 4, name: 'Trade Republic', logoUrl: '/images/loghi/tradeRepublic.png', status: 'WIP' },
    { id: 5, name: 'Coinbase', logoUrl: '/images/loghi/coinbase.png', status: 'WIP' },
    { id: 6, name: 'Revolut', status: 'voting', votes: 120 },
    { id: 7, name: 'Nexo', status: 'voting', votes: 98 },
    { id: 8, name: 'Kraken', status: 'voting', votes: 90 },
    { id: 9, name: 'Trading212', status: 'voting', votes: 85 },
    { id: 10, name: 'eToro', status: 'voting', votes: 80 },
    { id: 11, name: 'Bitstamp', status: 'voting', votes: 70 },
    { id: 12, name: 'Bitpanda', status: 'voting', votes: 65 },
    { id: 13, name: 'AVA Trade', status: 'voting', votes: 60 },
    { id: 14, name: 'XTB', status: 'voting', votes: 55 },
    { id: 15, name: 'Plus500', status: 'voting', votes: 50 },
    { id: 16, name: 'Swissquote', status: 'voting', votes: 50 },
    { id: 17, name: 'Robin Hood', status: 'voting', votes: 50 },
  ];

  const [platforms, setPlatforms] = useState(initialPlatforms);
  const [newPlatform, setNewPlatform] = useState<string>('');
  const [userVotes, setUserVotes] = useState<{ [key: number]: boolean }>({});
  const [showInput, setShowInput] = useState<boolean>(false);

  const handleVote = (id: number) => {
    setPlatforms((prevPlatforms) =>
      prevPlatforms
        .map((platform) =>
          platform.id === id ? { ...platform, votes: platform.votes! + 1 } : platform
        )
        .sort((a, b) => b.votes! - a.votes!)
    );
    setUserVotes((prevVotes) => ({ ...prevVotes, [id]: true }));
  };

  const handleAddPlatform = () => {
    if (newPlatform.trim() === '') return;
    const newId = platforms.length + 1;
    const newEntry: Platform = {
      id: newId,
      name: newPlatform,
      status: 'voting',
      votes: 0,
    };
    setPlatforms((prevPlatforms) => [...prevPlatforms, newEntry]); // New platform goes to the end
    setNewPlatform(''); // Clear input
    setShowInput(false); // Hide input field after adding
  };

  // Sort platforms by votes (descending order)
  const votingPlatforms = platforms.filter((platform) => platform.status === 'voting').sort((a, b) => b.votes! - a.votes!);

  return (
    <section className="relative py-10 overscroll-x-hidden max-w-screen">
      
      <h2 className="text-center text-2xl font-semibold mb-16 mt-12 z-10 relative">
        le piattaforme servite
      </h2>

      {/* Row for Ready and WIP platforms */}
      <div className="flex flex-wrap justify-center items-center gap-6 p-4 md:px-0 md:w-3/4 mx-auto mb-10 z-10 relative">
        {platforms
          .filter((platform) => platform.status === 'ready' || platform.status === 'WIP')
          .map((platform) => (
            <div className="platform-logo relative flex flex-col items-center justify-center text-center space-y-4 my-3 mx-6" key={platform.id}>
              <Image
                className="mx-auto"
                src={platform.logoUrl}
                alt={platform.name}
                width={0}  // Set the largest width (for bigger screens)
                height={0}   // Height is set to auto
                style={{ width: "100%", height:"auto" }} // Use CSS for styling
                sizes="160px"  // 128px for smaller screens, 160px for larger screens
              />
              {platform.status === 'WIP' && (
                <span className="absolute top-[-35px] right-[-40px] bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  In Sviluppo
                </span>
              )}
            </div>
          ))}
      </div>

      {/* Voting platforms
      <div className="text-center mt-8 z-10 relative">
        <h3 className="text-2xl font-semibold mb-12 mt-24">quale piattaforma ti serve?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-0 md:w-3/4 mx-auto">
          {votingPlatforms.map((platform, index) => (
            <div
              className="platform-voting relative flex items-center justify-between p-2 bg-gray-100 rounded-lg"
              key={platform.id}
              style={{ fontSize: `${Math.max(36 - index * 2, 16)}px` }} // Dynamically set the font size
            >
              <div className="flex items-center space-x-4">
                <span className="font-semibold text-gray-700">{index + 1}.</span>
                <span className="font-semibold text-gray-700">{platform.name}</span>
                <span className="text-sm text-gray-500">({platform.votes} voti)</span>
              </div>
              <div className="flex items-center">
                <button
                  className={`p-2 rounded-full bg-primary ${
                    userVotes[platform.id] ? 'opacity-10 hover:opacity-10 cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => handleVote(platform.id)}
                  disabled={userVotes[platform.id]}
                >
                  <ChevronUpIcon className="h-5 w-5 text-white font-bold" />
                </button>
              </div>
            </div>
          ))}
          <div className="platform-voting relative flex items-center justify-between p-4 bg-gray-100 rounded-lg">
            <div className="flex items-center space-x-4">
              <span className="text-lg font-bold text-black">{votingPlatforms.length + 1}.</span>
              <span className="text-lg font-bold text-black">quale vorresti?</span>
            </div>
            <div className="flex items-center">
              {showInput ? (
                <div className="mb-4 w-full">
                  <input
                    className="border border-gray-300 rounded-lg p-2 mr-4 w-full"
                    type="text"
                    value={newPlatform}
                    onChange={(e) => setNewPlatform(e.target.value)}
                    placeholder="Inserisci nuova piattaforma"
                  />
                  <Button variant="orange" onClick={handleAddPlatform}>
                    Aggiungi
                  </Button>
                </div>
              ) : (
                <button
                  className="p-2 rounded-full bg-secondary cursor-pointer"
                  onClick={() => setShowInput(true)}
                >
                  <PlusIcon className="h-5 w-5 text-white font-bold" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default SupportedPlatforms;
