import React from 'react';
import Image from 'next/image';

const Indoctrination: React.FC = () => {
  return (
    <div className="relative overflow-hidden w-full overscroll-x-hidden max-w-screen">

      <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
        {/* First Section - Text on Right, Image on Left */}
        <div className="flex flex-col md:flex-row items-center py-12 px-8">
          <div className="md:w-1/2 mx-auto mt-[-100px] hidden md:block">
            <Image src="/images/shape1.svg" alt="Quadrati Logo" width={300} height={256} />
          </div>
          <div className="w-full md:w-1/2 text-black text-left md:ml-8 md:mb-12">
            <h2 className="text-2xl text-primary font-bold mb-4">Si, ti capisco.</h2>
            <p className="leading-8 font-lato mt-12">
              Che noia!<br />
              C&apos;è chi dice che è meglio investire tramite banche o piattaforme italiane per evitare casini.<br />
              Ma sai cosa?<br />
              Ci costano di più e ci tassano automaticamente (che fastidio!).<br />
              Con un broker straniero, paghi le tasse dopo molto tempo, a volte anche dopo un anno e mezzo.<br />
              Pagare dopo rende meglio.<br />
              E spesso le commissioni sono pure più basse.
            </p>
          </div>
        </div>

        {/* Second Section - Image on Left, Text Aligned Right */}
        <div className="relative flex flex-col md:flex-row items-start py-12 px-8 h-auto">
          <div className="md:w-1/4 hidden md:block">
            <Image src="/images/odd_face.png" alt="Odd Face" width={150} height={474} />
          </div>
          <div className="md:w-3/4 text-black text-left md:ml-8 mb-12 md:mb-36">
            <h2 className="text-2xl text-primary font-bold">Anch’io pensavo...</h2>
            <div className="w-full leading-8 font-lato mt-12 md:w-2/3">
              <p className="text-black font-lato leading-8 mb-4 mx-auto mt-6">
                <strong>&quot;Dichiarerò solo quando vendo&quot;</strong><br />
                E poi...<br />
                Ho capito che se sei in Italia, hai l&apos;obbligo del monitoraggio.<br />
                E se non lo fai, le multe sono pesanti.<br />
                E l&apos;Agenzia delle Entrate?<br />
                Ora ci controlla tutti con un software che fa controlli incrociati e sanzioni automatiche.<br />
                Non c&apos;è scampo!<br /><br />
              </p>
              <p className="text-black font-lato leading-8 mb-4 mx-auto mt-6">
                <strong>&quot;Vado a Dubai e pago ZERO&quot;</strong><br />
                Me lo hanno fatto credere...<br />
                Ma...<br />
                Non funziona così!<br />
                Devi trasferirti a Dubai per un anno prima di vendere i tuoi investimenti.<br />
                Sei pronto a tutto quel casino burocratico?<br />
                E nel frattempo?<br />
                Hai comunque obblighi dichiarativi qui in Italia.<br />
                Punto.<br /><br />
              </p>
              <p className="text-black font-lato leading-8 mb-4 mx-auto mt-6">
                <strong>&quot;Investo in criptovalute e non sono tracciabile&quot;</strong><br />
                Ho scoperto che...<br />
                Proprio le piattaforme stesse ci “tradiscono”!<br />
                Per scambiare criptovalute, usiamo le piattaforme di trading, tipo Coinbase.<br />
                Ma attento che devono segnalare tutto all&apos;Agenzia delle Entrate.<br />
                Quindi, sì, ci tengono d&apos;occhio anche con le cripto.<br />
                Che presa per il cuneo!<br />
                Tanto dolore.
              </p>
          </div>
        </div>

          {/* Background Elements */}
          <div className="absolute top-0 left-0 w-full h-full hidden md:block">
            <Image src="/images/squares.svg" alt="Quadrati" className="w-4/5 mt-12 mx-auto h-auto opacity-80" width={414} height={532} />
          </div>
          <div className="absolute bottom-0 right-0 hidden md:block">
            <Image src="/images/triangle.svg" alt="Triangoli" width={250} height={275} />
          </div>
        </div>

        {/* Third Section - Image on Right, Circle Overlap */}
        <h2 className="text-2xl text-primary w-full font-bold px-8 text-center">Ok, quindi dichiarare sembra il modo migliore per evitare problemi?</h2>
        <div className="relative flex flex-col-reverse md:flex-row items-start py-6 px-8">
          <div className="w-full md:w-1/2 text-black text-center md:pr-12">
            <p className="leading-8 text-left font-lato md:mt-24 md:mb-24">
              A volte puoi pure sfruttare le perdite per pagare meno tasse.<br />
              Ma evitare di dichiarare non è solo costoso, è anche stupido!
            </p>
          </div>

          <div className="relative md:w-2/3 flex justify-center mx-auto my-8 md:mt-0">
            <Image className="rounded-full border-[12px] border-primary" src="/images/circle.png" alt="Circle Image" width={400} height={400} />
            <div className="absolute top-2 left-2 w-24 h-24 bg-secondary font-bold rounded-full text-white text-4xl flex justify-center items-center">
              Sì
            </div>
          </div>

          {/* Dots */}
          <div className="absolute bottom-[60px] left-[-20px] hidden md:block">
            <Image src="/images/dots.svg" alt="Puntini" width={150} height={56} />
          </div>
        </div>

        {/* Fourth Section - Centered Text with Background Shapes */}
        <div className="relative text-left py-12 px-8 md:mb-12 md:mb-24">
          <div className="mx-auto w-full md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Ma come faccio?</h2>
            <p className="text-black font-lato leading-8 mb-4 mt-12">
              Puoi scaricare la dichiarazione precompilata direttamente dal tuo broker.<br />
              Peccato che spesso sia piena di errori, e le conseguenze le paghi tu!<br />
              Inoltre, se hai più broker le devi interpretare e unire.<br />
              Rotture su rotture, insomma.
            </p>
            <p className="text-black font-lato leading-8 mb-4 mt-6">
              Puoi affidarti a un commercialista o al CAF.<br />
              Ma attento, spesso non capiscono un tubo di investimenti.<br />
              E ti ritrovi a portare documenti avanti e indietro fino a perdere la pazienza.
            </p>
            <p className="text-black font-lato leading-8 mb-4 mt-6">
              Oppure te la cavi da solo.<br />
              A parte perderti un weekend e non sarai mai sicuro al 100% di aver inserito tutti i dati correttamente.<br />
              Ansia.<br />
              Ognuna di queste opzioni è un macello.
            </p>
          </div>

          {/* Background shapes */}
          <div className="absolute bottom-0 left-0 hidden md:block">
            <Image src="/images/squares_orange.svg" alt="Quadrati Arancio" width={150} height={329} />
          </div>
          <div className="absolute bottom-0 right-0 hidden md:block">
            <Image src="/images/squares_purple.svg" alt="Quadrati Viola" width={150} height={118} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indoctrination;
